import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView'
import LoginView from '../views/LoginView.vue'
import PageNotFound from '@/views/PageNotFound'
import PartnerView from '@/views/PartnerView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home-page',
    component: HomeView,
    meta: { layout: 'base-layout' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { layout: 'base-layout' }
  },
  {
    path: '/register',
    name: 'register',
    component: PartnerView,
    meta: { layout: 'base-layout' }
  },
  {
    path: '/dashboard/profile',
    name: 'profile',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Profile/UserProfileView')
  },
  {
    path: '/dashboard/catalog',
    name: 'catalog',
    meta: { layout: 'dashboard-layout' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Catalog/CatalogView.vue')
  },
  {
    path: '/dashboard/catalog/:id',
    name: 'catalog-item',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Catalog/CatalogView.vue')
  },
  {
    path: '/dashboard/catalog/:id/products',
    name: 'catalog-products',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/CategoryProductsView.vue')
  },
  {
    path: '/dashboard/products',
    name: 'products',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/ProductsView.vue')
  },
  {
    path: '/dashboard/products/:id',
    name: 'product-item',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/ProductView.vue')
  },
  {
    path: '/dashboard/news',
    name: 'news',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/NewProductsView')
  },
  {
    path: '/dashboard/specials',
    name: 'specials',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/SpecialProductsView')
  },
  {
    path: '/dashboard/favorites',
    name: 'favorites',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/FavoriteProductsView')
  },
  {
    path: '/dashboard/cart',
    name: 'cart',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Cart/CartView.vue')
  },
  {
    path: '/dashboard/orders',
    name: 'order-list',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Orders/OrderListView')
  },
  {
    path: '/dashboard/orders/:id',
    name: 'order-view',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Orders/OrderView')
  },
  {
    path: '/dashboard/article/:id',
    name: 'article-view',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Articles/ArticleView')
  },
  {
    path: '/dashboard/notifications',
    name: 'notification-list-view',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Notifications/NotificationListView')
  },
  {
    path: '*',
    meta: { layout: 'base-layout' },
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
