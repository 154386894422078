import axios from 'axios'

const baseURL = process.env.VUE_APP_ORDER_SERVICE + '/api/orders'

export default {
  namespaced: true,
  state: {
    is_load_orders: false,
    list_per_page: [10, 20, 30, 50],
    orders: [],
    order: null,
    order_pagination: {
      current_page: 1,
      per_page: 10,
      total: 0
    },
    order_filters: {
      search: null,
      order_by: 'id',
      order_direction: 'desc'
    },
    logs: null
  },
  mutations: {
    setIsLoadOrders (state, value) {
      state.is_load_orders = value === true
    },
    setOrders (state, payload) {
      state.orders = payload
    },
    setOrderPagination (state, payload) {
      state.order_pagination = payload
    },
    setOrderPage (state, payload) {
      state.order_pagination.current_page = payload
    },
    setOrderPerPage (state, payload) {
      state.order_pagination.per_page = payload
      localStorage.setItem('etalon-orders-per-page', payload)
    },
    setOrder (state, payload) {
      state.order = payload
    },
    setOrderSearch (state, payload) {
      state.order_filters.search = payload
    },
    setSorting (state, data) {
      if (!data.order_by) {
        state.order_filters.order_by = 'id'
        state.order_filters.order_direction = 'desc'
        return
      }
      state.order_filters.order_by = data.order_by || 'id'
      state.order_filters.order_direction = data.order_direction ? 'asc' : 'desc'
    },
    setLogs (state, payload) {
      state.logs = payload
    }
  },
  actions: {
    async getOrders ({
      state,
      commit
    }) {
      commit('setIsLoadOrders', true)
      const promise = axios.get(baseURL, {
        params: {
          page: state.order_pagination.current_page,
          per_page: state.order_pagination.per_page,
          search: state.order_filters.search,
          order_by: state.order_filters.order_by,
          order_direction: state.order_filters.order_direction
        }
      })
      promise.then(response => {
        commit('setOrders', response.data.data)
        commit('setOrderPagination', response.data.pagination)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async getOrder ({
      state,
      commit
    }, id) {
      commit('setIsLoadOrders', true)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async createOrder ({
      state,
      commit,
      rootState,
      rootGetters
    }, data) {
      const requestData = {
        user_id: rootState.auth.user.id,
        user_code_id: data.user_code_id ? data.user_code_id : rootState.auth.user.user_code_id,
        tp_prefix: data.tp_prefix,
        is_online: rootState.auth.user.is_online ? 1 : 0,
        pay_method_id: data.pay_method_id,
        delivery_method_id: data.delivery_method_id,
        user: rootState.auth.user,
        delivery: data.delivery,
        comment: data.comment,
        products: rootGetters['carts/getSelectedCart']
      }

      return axios.post(baseURL, requestData)
    },
    printOrder ({ state }, id) {
      axios.get(baseURL + '/' + id + '/view').then(r => {
        const printWindow = window.open('', '', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0')
        printWindow.document.write(r.data)
        printWindow.document.close()
        printWindow.focus()
        printWindow.print()
      })
    },
    excelOrder ({ state }, id) {
      const config = {
        responseType: 'blob'
      }
      axios.get(baseURL + '/' + id + '/xlsx', config)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'order-' + state.order.number + '.xlsx')
          document.body.appendChild(link)
          link.click()
        })
    },
    pdfOrder ({ state }, id) {
      const config = {
        responseType: 'blob'
      }
      axios.get(baseURL + '/' + id + '/pdf', config)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'order-' + state.order.number + '.pdf')
          document.body.appendChild(link)
          link.click()
        })
    }
  }
}
