import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/search'

export default {
  namespaced: true,
  state: {
    search_request: null,
    autocomplete: [],
    search_products: [],
    search_categories: [],
    search_history: localStorage.getItem('etalon-search-history') ? JSON.parse(localStorage.getItem('apro-etalon-history')) : []
  },
  mutations: {
    setSearchRequest (state, v) {
      state.search_request = v
    },
    setAutocomplete (state, payload) {
      state.autocomplete = payload
    },
    setSearchProducts (state, payload) {
      state.search_products = payload
    },
    setSearchCategories (state, payload) {
      state.search_categories = payload
    },
    addSearchHistory (state, string) {
      state.search_history.unshift(string)
      if (state.search_history.length > 5) {
        state.search_history.pop()
      }
      localStorage.setItem('apro-search-history', JSON.stringify(state.search_history))
    }
  },
  actions: {
    async searchRequest ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL, {
        params: {
          search: state.search_request
        }
      })
      promise.then(response => {
        commit('setAutocomplete', response.data.autocomplete)
        commit('setSearchProducts', response.data.products)
        commit('setSearchCategories', response.data.categories)
      }).catch(e => {
      })
      return promise
    }
  }
}
