import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export const productMixin = {
  props: [
    'category_id'
  ],
  computed: {
    ...mapGetters('filters', ['selectedFilterChips']),
    ...mapState('products', [
      'products',
      'product_pagination',
      'product_filters',
      'list_per_page',
      'is_load_products',
      'image_url',
      'images'
    ]),
    ...mapState('filters', [
      'category_filters',
      'selected_filters',
      'show_panel',
      'is_loading_filters',
      'base_type'
    ]),
    headers: {
      get () {
        return [
          {
            text: this.$t('products.list.code'),
            value: 'code',
            sortable: true
          },
          {
            text: 'Фото',
            value: 'has_images',
            align: 'left',
            sortable: false,
            cellClass: 'photo-item'
          },
          {
            text: this.$t('products.list.name'),
            value: 'name',
            sortable: true
          },
          {
            text: this.$t('products.list.price_currency'),
            value: 'price',
            sortable: true
          },
          {
            text: this.$t('products.list.in_pack'),
            value: 'in_pack',
            sortable: false
          },
          {
            text: this.$t('products.list.qty'),
            value: 'action',
            sortable: false,
            align: 'right'
          }
        ]
      }
    },
    computedSortList: {
      get () {
        const s = this.sortList
        if (this.product_filters.search) {
          s.unshift({
            value: 99,
            text: this.$t('sort_products.relevance')
          })
        }
        return s
      }
    },
    navigation: {
      get () {
        return {
          page: this.product_pagination.current_page,
          per_page: this.product_pagination.per_page,
          search: this.product_filters.search,
          category_id: this.category_id,
          filters: this.product_filters.filters
        }
      },
      set (data) {
        if (typeof data.filters === 'string') {
          data.filters = [data.filters]
        }
        if (data.filters === undefined) {
          data.filters = []
        }
        this.setProductPage(parseInt(data.page))
        this.setProductPerPage(data.per_page)
        this.setProductSearch(data.search)
        this.setCategoryId(data.category_id)
        this.setSelectedFilters(data.filters)
        this.setFiltersQuery(data.filters)

        this.$router.replace({
          query: {
            ...this.$route.query,
            page: parseInt(data.page),
            per_page: parseInt(data.per_page),
            search: data.search,
            filters: data.filters
          }
        }).catch(e => {
        })
      }
    }
  },
  methods: {
    ...mapActions('products', [
      'getProducts',
      'addToFavorite',
      'deleteFromFavorite',
      'getImages'
    ]),
    ...mapMutations('products', [
      'setProducts',
      'setProductPage',
      'setProductPerPage',
      'setCategoryId',
      'setProductSearch',
      'setSorting',
      'setInStock',
      'setSpecials',
      'setFavorites',
      'setNews',
      'setFiltersQuery',
      'setSpecials'
    ]),
    ...mapActions('analogues', [
      'getAnalogues']),
    ...mapMutations('analogues', [
      'setShowAnalogies'
    ]),
    ...mapActions('filters', ['getFilterList']),
    ...mapMutations('filters', [
      'setCategoryFilters',
      'setSelectedFilters',
      'setShowPanel',
      'deleteSelectedFilterItem'
    ]),
    handlerPaginate (page) {
      this.navigation = {
        page: page,
        per_page: this.navigation.per_page,
        search: this.navigation.search,
        filters: this.navigation.filters
      }
    },
    handlerPerPage (perPage) {
      this.navigation = {
        page: 1,
        per_page: perPage,
        search: this.navigation.search,
        filters: this.navigation.filters
      }
    },
    clickRow (v) {
      if (window.getSelection().toString()) {
        return
      }

      const currentUrl = this.$route.fullPath
      localStorage.setItem('product-scroll', JSON.stringify({
        to: window.scrollY,
        url: currentUrl
      }))

      this.$router.push('/dashboard/products/' + v.id)
    }
  }
}
