<template>
  <v-container fluid class="set-font">
    <v-layout class="mt-16" align-center justify-center>
      <v-card flat width="360">
        <a class="text-center" style="color: black; text-decoration: none" href="/">
          <v-img class="mb-0" height="50px" style="margin: auto" max-width="300px" src="logo-dark.png"/>
          <p style="font-size: 20px">партнерство для успіху</p>
        </a>
        <v-divider></v-divider>
        <v-toolbar
          flat
        >
          <v-toolbar-title style="margin: auto">
            <b>Вхід до особистого кабінету</b>
          </v-toolbar-title>
        </v-toolbar>
        <!--        <v-divider></v-divider>-->
        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              autofocus
              outlined
              v-model="login"
              :label="$t('login.login')"
              name="login"
              type="text"
              autocomplete="username"
              :error="hasError('login')"
              :error-messages="getError('login')"
              @keyup.enter="sendLogin"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="password"
              :label="$t('login.password')"
              name="password"
              :type="password_field_type ? 'password' : 'text'"
              :error="hasError('password')"
              :error-messages="getError('password')"
              :append-icon="password_field_type ? 'visibility' : 'visibility_off'"
              @click:append="password_field_type = !password_field_type"
              @keyup.enter="sendLogin"
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="sendLogin()"
              large
              width="100%"
            >
              {{ $t('login.sign_in') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'LoginView',
  data: () => ({
    login: null,
    password: null,
    password_field_type: true
  }),
  watch: {
    user: {
      handler (v) {
        if (v.id) {
          this.$router.push('/dashboard/catalog')
        }
      }
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError', 'getError']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['loginRequest', 'getUser']),
    ...mapMutations('auth', ['setAccessToken']),
    sendLogin () {
      const promise = this.loginRequest({
        login: this.login,
        password: this.password
      })

      promise.then(r => {
        window.location = '/dashboard/catalog'
      })
    }
  },
  created () {
    const token = this.$route.query.token

    if (token) {
      this.setAccessToken(token)
    }

    const promise = this.getUser()
    promise.then(r => {
      this.$router.push('/dashboard/catalog')
      // window.location = '/dashboard/catalog'
    })
  }
}
</script>
