import { mapActions, mapMutations, mapState } from 'vuex'

export const ws = {
  data () {
    return {
      showTelegramModel: false
    }
  },
  computed: {
    ...mapState('notifications', ['count_unread']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('notifications', ['getCountUnread', 'getNotifications']),
    ...mapMutations('notifications', ['setNotificationPage']),
    joinChannelNotifications () {
      this.getCountUnread()
      window.Echo.channel('user.' + this.user.id).listen('.users.new-notification', (e) => {
        this.getCountUnread()
      }).listen('.users.join-telegram', (e) => {
        this.user.telegram_chat = e.telegram_chat
        this.showTelegramModel = false
      })
    }
  }
}
