<template>
  <v-navigation-drawer
    app
    v-model="show"
    right
    temporary
    clipped
    width="1100px"
  >
    <v-card flat>
      <v-toolbar
        color="rgba(0, 0, 0, 0)"
        theme="dark"
        flat
      >
        <v-btn icon @click="show = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <div>Аналоги</div>
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-text-field
          v-model="search"
          :placeholder="$t('filters.filter')"
          append-icon="filter_alt"
          hide-details
        ></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
        <ProductListMiniTable
          :loading="is_load_analogues"
          :items="analogues"
          :search="search_string"
        />
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import ProductListMiniTable from '@/components/Products/Components/ProductListMiniTable'

export default {
  name: 'SecondAsidePanel',
  components: { ProductListMiniTable },
  data () {
    return {
      searchTimer: null,
      search_string: null
    }
  },
  computed: {
    ...mapState('analogues', [
      'show_analogues_panel',
      'analogues',
      'is_load_analogues'
    ]),
    search: {
      get () {
        return this.search_string
      },
      set (v) {
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => {
          this.search_string = v
        }, 100)
      }
    },
    show: {
      get () {
        return this.show_analogues_panel
      },
      set (v) {
        this.setShowAnalogies(v)
        this.search = null
        if (!v) {
          this.setAnalogues([])
        }
      }
    }
  },
  methods: {
    ...mapMutations('analogues', [
      'setShowAnalogies',
      'setAnalogues'
    ])
  }
}
</script>

<style scoped>

</style>
