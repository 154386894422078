<template>
  <v-main>
    <router-view/>
  </v-main>
</template>

<script>
export default {
  name: 'BaseLayout'
}
</script>

<style scoped>

</style>
