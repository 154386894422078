<template>
  <v-data-table
    :loading-text="$t('loading_data')"
    :search="search"
    :loading="loading"
    :headers="headers"
    :items="items"
    hide-default-footer
    mobile-breakpoint="1"
    :items-per-page="-1"
  >
    <template v-slot:[`item.has_images`]="{item}">
      <v-img
        v-if="item.midi_image_url" :src="item.midi_image_url"
        max-height="66px"
        max-width="100px"
      />
      <v-img v-else src="/no-img.png"
             max-height="66px"
             max-width="100px"
      />
    </template>
    <template v-slot:[`item.name`]="{item}">
      <div class="align-center">
        <div style="min-width: 360px">
          <a class="product-name-list" target="_blank" :href="'/dashboard/products/' + item.id">{{
              _tl(item, 'name')
            }}</a>
          <div>
            <small>{{ $t('products.list.code') }}:<b> {{ item.code }}</b></small> |
            <small>{{ $t('products.list.vendor_code') }}: <b>{{ item.vendor_code }}</b></small>
          </div>
          <div>
            <v-tooltip bottom v-if="item.is_favorite">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="deleteFromFavorite(item.id)"
                  icon
                  class="mr-2 gr"
                  x-small
                >
                  <v-icon>favorite</v-icon>
                </v-btn>
              </template>
              {{ $t('products.delete_from_favorites') }}
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="addToFavorite(item.id)"
                  icon
                  class="mr-2"
                  x-small
                >
                  <v-icon>favorite</v-icon>
                </v-btn>
              </template>
              {{ $t('products.add_to_favorites') }}
            </v-tooltip>
            <v-chip
              v-if="item.special_price"
              color="red"
              class="ml-2"
              x-small
              dark
            >
              {{ item.discount ? $t('products.individual_special') : $t('products.special') }}
            </v-chip>
            <v-chip
              v-if="item.is_new"
              color="orange"
              class="ml-2"
              x-small
              dark
            >
              {{ $t('products.new') }}
            </v-chip>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:[`item.price`]="{item}">
      <div style="min-width: 75px">
        <span v-if="item.special_price">
              <s class="grey--text text--darken-1">{{ getFormattedPrice(item.price) }} </s>
              <br>
              <b class="red--text" v-html="getFormattedPrice(item.special_price, false, true)"></b>
        </span>
        <span v-else v-html="getFormattedPrice(item.price, false, true)"></span>
      </div>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <div class="d-flex justify-end align-center">
        <div v-if="item.qty > 0" class="mr-6 text-no-space">
          {{ item.qty <= 10 ? item.qty : '< 10' }}
        </div>
        <CartQtyInput
          v-if="item.qty > 0"
          :item="item"
        />
        <NotifyAvailableProduct :product="item" v-else/>
      </div>
    </template>
    <template
      v-slot:[`header.action`]="head"
    >
      <span class="qty-head text-no-wrap">{{ head.header.text }}</span>
    </template>
  </v-data-table>
</template>

<script>
import NotifyAvailableProduct from '@/components/Products/NotifyAvailableProduct'
import CartQtyInput from '@/components/Products/Components/CartQtyInput'
import { productMixin } from '@/mixins/v1/product_mixin'
import { price } from '@/mixins/price'
import { translate } from '@/mixins/translate'

export default {
  name: 'ProductListMiniTable',
  mixins: [
    productMixin,
    price,
    translate
  ],
  props: [
    'loading',
    'items',
    'search'
  ],
  computed: {
    headers: {
      get () {
        return [
          {
            text: 'Фото',
            value: 'has_images',
            align: 'left',
            sortable: false,
            cellClass: 'photo-item'
          },
          {
            text: this.$t('products.list.name'),
            value: 'name',
            sortable: true
          },
          {
            text: this.$t('products.list.price_currency'),
            value: 'price',
            sortable: true
          },
          {
            text: this.$t('products.list.qty'),
            value: 'action',
            sortable: false,
            align: 'right'
          }
        ]
      }
    }
  },
  components: {
    CartQtyInput,
    NotifyAvailableProduct
  },
  methods: {
    showAnalogies (id) {
      this.setShowAnalogies(true)
      this.getAnalogues(id)
    }
  }
}
</script>

<style scoped>
.gr >>> i {
  background: linear-gradient(to bottom, #0057b8 50%, #ffd700 50%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
</style>
