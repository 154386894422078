import { mapGetters, mapMutations, mapState } from 'vuex'

export const price = {
  computed: {
    ...mapGetters('prices', ['price_type']),
    ...mapState('prices', ['selected_type']),
    priceTypeList: {
      get () {
        return [
          {
            value: 0,
            text: this.$t('price_types.uah'),
            short: ''
          },
          {
            value: 1,
            text: this.$t('price_types.fop'),
            short: this.$t('price_types.fop_short')
          },
          {
            value: 2,
            text: this.$t('price_types.nds'),
            short: this.$t('price_types.nds_short')
          }
        ]
      }
    },
    priceType: {
      get () {
        return parseInt(this.selected_type)
      },
      set (v) {
        this.setPriceType(v)
      }
    },
    sort: {
      get () {
        if (this.product_filters.search_sort) {
          return 99
        }

        return parseInt(this.product_filters.sorting)
      },
      set (v) {
        this.setSorting(v)
        this.getProducts()
      }
    },
    sortList: {
      get () {
        return [
          {
            value: 1,
            text: this.$t('sort_products.price_asc')
          },
          {
            value: 2,
            text: this.$t('sort_products.price_desc')
          },
          {
            value: 3,
            text: this.$t('sort_products.name')
          },
          {
            value: 4,
            text: this.$t('sort_products.name_desc')
          },
          {
            value: 5,
            text: this.$t('sort_products.code')
          },
          {
            value: 6,
            text: this.$t('sort_products.code_desc')
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations('prices', ['setPriceType']),
    formatPrice (amount) {
      if (!amount) {
        amount = 0
      }

      const c = amount.toLocaleString('uk-UK', {
        style: 'currency',
        currency: 'UAH'
      })

      return c.replace('грн', '').replace('₴', '')
    },
    calcPrice (value) {
      if (!value) {
        return 0
      }

      return value * (this.price_type.percent / 100) + value
    },
    getFormattedPrice (price, showCurrency = false, showType = false, showSymbol = false) {
      if (!price) {
        price = 0
      } else {
        price = price * (this.price_type.percent / 100) + price
      }
      let c = price.toLocaleString('uk-UK', {
        style: 'currency',
        currency: 'UAH'
      })

      c = c.replace('грн', '').replace('₴', '')

      if (showCurrency) {
        c += 'грн'
      }

      if (showSymbol) {
        c += '₴'
      }

      if (showType) {
        const type = this.priceTypeList ? this.priceTypeList[this.priceType] : null
        if (type && type.short) {
          c += '<br><small> (' + type.short + ')</small>'
        }
      }

      return c
    }
  }
}
