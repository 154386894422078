import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/analogues'

export default {
  namespaced: true,
  state: {
    is_load_analogues: false,
    analogues: [],
    show_analogues_panel: false
  },
  mutations: {
    setLoadAnalogues (state, value) {
      state.is_load_analogues = value === true
    },
    setAnalogues (state, payload) {
      state.analogues = payload
    },
    setShowAnalogies (state, v) {
      state.show_analogues_panel = v
    },
    setNotifyProduct (state, payload) {
      const index = state.analogues.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.analogues[index].product_notifications = payload.value
        state.analogues = [...state.analogues]
      }
    },
    markFavorite (state, payload) {
      const index = state.analogues.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.analogues[index].is_favorite = payload.is_favorite
        state.analogues = [...state.analogues]
      }
    }
  },
  actions: {
    async getAnalogues ({
      state,
      commit
    }, id) {
      commit('setLoadAnalogues', true)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setAnalogues', response.data.data)
        commit('setLoadAnalogues', false)
      }).catch(e => {
        commit('setLoadAnalogues', false)
      })
      return promise
    }
  }
}
