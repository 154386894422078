import { mapState } from 'vuex'

export const translate = {
  computed: {
    ...mapState('lang', ['lang'])
  },
  methods: {
    /**
     * Translate field
     * @param data
     * @param fieldName
     * @returns {*}
     * @private
     */
    _tl (data, fieldName) {
      let langField = fieldName
      if (this.lang === 'ua') {
        langField = fieldName + '_uk'
      }

      return data[langField] ? data[langField] : data[fieldName]
    }
  }
}
