import axios from 'axios'
import i18n from '@/i18n'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/products'

export default {
  namespaced: true,
  state: {
    image_url: process.env.VUE_APP_IMAGE_SERVER,
    is_load_products: false,
    is_update_images: false,
    list_per_page: [10, 20, 30, 50],
    products: [],
    product: null,
    product_pagination: {
      current_page: null,
      per_page: localStorage.getItem('etalon-products-per-page') || 10,
      total: 0
    },
    images: [],
    product_filters: {
      filters: [],
      specials: null,
      favorites: null,
      news: null,
      search: null,
      search_sort: false,
      sorting: localStorage.getItem('etalon-products-sort') || 5,
      order_by: 'code',
      order_direction: 'asc',
      in_stock: localStorage.getItem('etalon-in-stock') === 'true'
    },
    current_category: null,
    category_id: null,
    breadcrumbs: []
  },
  mutations: {
    setLoadProducts (state, value) {
      state.is_load_products = value === true
    },
    setUpdateImages (state, value) {
      state.is_update_images = value === true
    },
    setProducts (state, payload) {
      state.products = payload
    },
    setProductPagination (state, payload) {
      state.product_pagination = payload
    },
    setProductPage (state, payload) {
      state.product_pagination.current_page = payload
    },
    setProductPerPage (state, payload) {
      state.product_pagination.per_page = payload
      localStorage.setItem('etalon-products-per-page', payload)
    },
    setFiltersQuery (state, payload) {
      state.product_filters.filters = payload
    },
    setCategoryId (state, payload) {
      state.category_id = payload
    },
    setProduct (state, payload) {
      state.product = payload
    },
    setProductSearch (state, payload) {
      state.product_filters.search = payload
    },
    setInStock (state, v) {
      state.product_filters.in_stock = v
      localStorage.setItem('etalon-in-stock', v)
    },
    setSpecials (state, v) {
      state.product_filters.specials = v
    },
    setFavorites (state, v) {
      state.product_filters.favorites = v
    },
    setNews (state, v) {
      state.product_filters.news = v
    },
    setWithoutCategory (state, v) {
      state.product_filters.without_category = v
    },
    setBreadcrumbs (state, payload) {
      state.breadcrumbs = payload
    },
    setImages (state, payload) {
      state.images = payload
    },
    setCurrentCategory (state, payload) {
      state.current_category = payload
    },
    setSorting (state, v) {
      if (v === 99) {
        state.product_filters.search_sort = true
        return
      }
      state.product_filters.sorting = v
      state.product_filters.search_sort = false
      localStorage.setItem('etalon-products-sort', v)
    },
    setNotifyProduct (state, payload) {
      const index = state.products.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.products[index].product_notifications = payload.value
        state.products = [...state.products]
      }

      if (state.product && state.product.id === payload.id) {
        state.product.product_notifications = payload.value
        state.product = [...state.product]
      }
    },
    markFavorite (state, payload) {
      const index = state.products.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.products[index].is_favorite = payload.is_favorite
        state.products = [...state.products]
      }
    }
  },
  actions: {
    async getProducts ({
      state,
      commit
    }) {
      commit('setLoadProducts', true)

      if (!state.product_filters.search_sort) {
        switch (parseInt(state.product_filters.sorting)) {
          case 1:
            state.product_filters.order_by = 'price'
            state.product_filters.order_direction = 'asc'
            break
          case 2:
            state.product_filters.order_by = 'price'
            state.product_filters.order_direction = 'desc'
            break
          case 3:
            state.product_filters.order_by = 'name'
            if (i18n.locale === 'ua') {
              state.product_filters.order_by = 'name_uk'
            }
            state.product_filters.order_direction = 'asc'
            break
          case 4:
            state.product_filters.order_by = 'name'
            if (i18n.locale === 'ua') {
              state.product_filters.order_by = 'name_uk'
            }
            state.product_filters.order_direction = 'desc'
            break
          case 5:
            state.product_filters.order_by = 'code'
            state.product_filters.order_direction = 'asc'
            break
          case 6:
            state.product_filters.order_by = 'code'
            state.product_filters.order_direction = 'desc'
            break
        }
      }

      const promise = axios.get(baseURL, {
        params: {
          category_id: state.category_id,
          page: state.product_pagination.current_page,
          per_page: state.product_pagination.per_page,
          search: state.product_filters.search,
          search_sort: state.product_filters.search_sort === true ? 1 : 0,
          order_by: state.product_filters.order_by,
          order_direction: state.product_filters.order_direction,
          in_stock: state.product_filters.in_stock === true ? 1 : 0,
          specials: state.product_filters.specials === true ? 1 : 0,
          favorites: state.product_filters.favorites === true ? 1 : 0,
          news: state.product_filters.news === true ? 1 : 0,
          filters: state.product_filters.filters
        }
      })
      promise.then(response => {
        commit('setProducts', response.data.data)
        commit('setProductPagination', response.data.meta)
        commit('setLoadProducts', false)
        commit('setBreadcrumbs', response.data.breadcrumbs)
        commit('setCurrentCategory', response.data.category)
      }).catch(e => {
        commit('setLoadProducts', false)
      })
      return promise
    },
    async getProduct ({
      state,
      commit
    }, id) {
      commit('setLoadProducts', true)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setProduct', response.data.data)
        commit('setLoadProducts', false)
      }).catch(e => {
        commit('setLoadProducts', false)
      })
      return promise
    },
    async uploadImages ({
      state,
      commit,
      dispatch
    }, data) {
      commit('setUpdateImages', true)
      const formData = new FormData()

      for (let i = 0; i < data.images.length; i++) {
        formData.append('files[' + i + ']', data.images[i])
      }

      const promise = axios.post(baseURL + '/' + data.id + '/upload', formData)
      promise.then(response => {
        commit('setUpdateImages', false)
        dispatch('getProduct', data.id)
      }).catch(e => {
        commit('setUpdateImages', false)
      })
      return promise
    },
    async deleteImage ({
      state,
      commit,
      dispatch
    }, data) {
      commit('setUpdateImages', true)
      const promise = axios.post(baseURL + '/' + data.id + '/delete', data)
      promise.then(response => {
        commit('setUpdateImages', false)
        dispatch('getProduct', data.id)
      }).catch(e => {
        commit('setUpdateImages', false)
      })
      return promise
    },
    async getImages ({
      state,
      commit
    }, id) {
      commit('setLoadProducts', true)
      const promise = axios.get(baseURL + '/' + id + '/images')
      promise.then(response => {
        commit('setImages', response.data.images)
        commit('setLoadProducts', false)
      }).catch(e => {
        commit('setLoadProducts', false)
      })
      return promise
    },
    async addToFavorite ({
      state,
      commit,
      dispatch
    }, id) {
      const promise = axios.post(baseURL + '/' + id + '/favorites')
      promise.then(response => {
        commit('markFavorite', {
          id: id,
          is_favorite: true
        })
        commit('analogues/markFavorite', {
          id: id,
          is_favorite: true
        }, { root: true })
      }).catch(e => {
      })
      return promise
    },
    async deleteFromFavorite ({
      state,
      commit,
      dispatch
    }, id) {
      const promise = axios.delete(baseURL + '/' + id + '/favorites')
      promise.then(response => {
        commit('markFavorite', {
          id: id,
          is_favorite: false
        })
        commit('analogues/markFavorite', {
          id: id,
          is_favorite: false
        }, { root: true })
      }).catch(e => {
      })
      return promise
    },
    async notifyWhenAvailable ({
      state,
      commit,
      dispatch
    }, id) {
      const promise = axios.post(baseURL + '/notify', {
        product_id: id
      })
      promise.then(response => {
        commit('setNotifyProduct', {
          id: id,
          value: 1
        })
        commit('analogues/setNotifyProduct', {
          id: id,
          value: 1
        }, { root: true })
      }).catch(e => {
      })
      return promise
    },
    async deleteNotifyWhenAvailable ({
      state,
      commit,
      dispatch
    }, id) {
      const promise = axios.delete(baseURL + '/notify', {
        params: {
          product_id: id
        }
      })
      promise.then(response => {
        commit('setNotifyProduct', {
          id: id,
          value: null
        })
        commit('analogues/setNotifyProduct', {
          id: id,
          value: null
        }, { root: true })
      }).catch(e => {
      })
      return promise
    }
  }
}
