import axios from 'axios'

const baseURL = process.env.VUE_APP_AUTH_SERVER

export default {
  namespaced: true,
  state: {
    count_unread: 0,
    is_notification_loading: false,
    notifications: [],
    notification_pagination: {
      current_page: 1,
      total: 0,
      last_page: 0
    }
  },
  mutations: {
    setCountUnread (state, count) {
      state.count_unread = count
    },
    setNotifications (state, count) {
      state.notifications = count
    },
    setReadNotification (state, id) {
      const index = state.notifications.findIndex(item => item.id === id)
      if (index !== -1) {
        state.notifications[index].read_at = 1
        state.notifications = [...state.notifications]
      }
    },
    setNotificationPage (state, page) {
      state.notification_pagination.current_page = page
    },
    setNotificationPagination (state, payload) {
      state.notification_pagination.current_page = payload.current_page ? payload.current_page : 1
      state.notification_pagination.total = payload.total ? payload.total : 0
      state.notification_pagination.last_page = payload.last_page ? payload.last_page : 0
    }
  },
  actions: {
    getCountUnread ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL + '/api/notifications/count')
      promise.then(response => {
        commit('setCountUnread', response.data.count)
      })
    },
    getNotifications ({
      state,
      commit
    }) {
      state.is_notification_loading = true
      const promise = axios.get(baseURL + '/api/notifications', {
        params: {
          page: state.notification_pagination.current_page
        }
      })
      promise.then(response => {
        commit('setNotifications', response.data.data)
        commit('setNotificationPagination', response.data)
        state.is_notification_loading = false
      })
    },
    markRead ({
      state,
      commit
    }, id) {
      const promise = axios.post(baseURL + '/api/notifications/read/' + id)
      promise.then(response => {
        commit('setReadNotification', id)
        commit('setCountUnread', state.count_unread - 1)
      })
    },
    deleteNotification ({
      state,
      commit,
      dispatch
    }, message) {
      const promise = axios.delete(baseURL + '/api/notifications/' + message.id)
      promise.then(response => {
        if (message.read_at === null) {
          commit('setCountUnread', state.count_unread - 1)
        }
        if (state.notification_pagination.current_page !== 1 &&
          (state.notifications.length === 1 || state.notifications.length === 0)
        ) {
          commit('setNotificationPage', state.notification_pagination.current_page - 1)
        }
        dispatch('getNotifications')
      })
    }
  }
}
