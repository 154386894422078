<template>
  <v-navigation-drawer
    class="main-bg"
    v-model="show_aside"
    app
    dark
    clipped
    :src="getBackgroundImage()"
    @input="setShow"
  >
    <v-list
      nav
      dense
    >
      <div v-if="this.$vuetify.breakpoint.mobile">
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex align-center justify-center">
              <v-img
                alt="Etalon Logo"
                class="shrink mr-2 ml-2"
                contain
                :src="getLogo()"
                transition="scale-transition"
                width="160"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
      <v-subheader>{{ $t('users.user') }}</v-subheader>
      <v-menu
        offset-x
        transition="scale-transition" class="mx-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2" dark>
              mdi-account-circle
            </v-icon>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item
            to="/dashboard/profile"
            dense
          >
            <v-list-item-title>{{ $t('users.profile') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            @click="logout()">
            <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider></v-divider>
      <v-subheader>Меню</v-subheader>
      <v-list-item-group
        active-class="gray--text"
      >
        <v-list-item to="/dashboard/catalog">
          <v-icon class="mr-2">list</v-icon>
          <v-list-item-title>Каталог</v-list-item-title>
        </v-list-item>
        <v-list-item
          style="background: rgb(228 24 58 / 75%)"
          tag="a"
          target="_blank"
          href="https://api-products.tm-etalon.com.ua/storage/Etalon_Specials.code.pdf"
        >
          <v-icon class="mr-2">folder_special</v-icon>
          <v-list-item-title>{{ $t('special_pdf') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/orders">
          <v-icon class="mr-2">wysiwyg</v-icon>
          <v-list-item-title>{{ $t('orders.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/news">
          <v-icon class="mr-2">fiber_new</v-icon>
          <v-list-item-title>{{ $t('catalog.new_products') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/specials">
          <v-icon class="mr-2">percent</v-icon>
          <v-list-item-title>{{ $t('catalog.special_products') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/favorites">
          <v-icon class="mr-2 gr">
            favorite
          </v-icon>
          <v-list-item-title>{{ $t('favorites.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="this.$vuetify.breakpoint.mobile" to="/dashboard/notifications">
          <v-icon class="mr-2">
            notifications
          </v-icon>
          <v-list-item-title>
            {{ $t('notifications.label') }}
            <v-badge
              class="ms-2"
              :content="count_unread"
              :value="count_unread"
              color="red"
            ></v-badge>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$vuetify.breakpoint.mobile && user && !user.telegram_chat"
          @click="show_join_telegram = true"
        >
          <v-img class="mr-2" height="24px" width="24px" src="/images/telegram-app.svg"/>
          <v-list-item-title>
            {{ $t('telegram.join') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-divider></v-divider>
      <v-list-item-group
        active-class="gray--text"
      >
        <v-list-item
          tag="a"
          target="_blank"
          href="https://drive.google.com/drive/folders/0ByRR6G3q3rDJaW9yelYwOV9KQjA?resourcekey=0-9hIE_a5psqp7KB5n2E4Q9w"
        >
          <v-icon class="mr-2">verified_user</v-icon>
          <v-list-item-title>{{ $t('other.cert') }}</v-list-item-title>
        </v-list-item>
        <!--        -->
        <v-list-item
          v-for="article in aside_articles"
          :to="'/dashboard/article/' + article.id"
          :key="article.id"
        >
          <v-icon class="mr-2" v-if="article.icon">{{ article.icon }}</v-icon>
          <v-list-item-title>{{ article.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <JoinTelegramBotModal v-model="show_join_telegram"/>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ws } from '@/mixins/ws_notification'
import JoinTelegramBotModal from '@/components/Telegram/Modals/JoinTelegramBotModal'

export default {
  name: 'MainMenuAsideComponent',
  components: { JoinTelegramBotModal },
  props: ['is_show'],
  mixins: [ws],
  data: () => ({
    show_aside: false,
    show_join_telegram: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('content', ['aside_articles'])
  },
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-main-menu', v)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('content', ['getAsideArticles']),
    setShow (v) {
      this.$emit('show-main-menu', v)
    },
    getLogo () {
      const today = new Date()
      const month = today.getMonth() + 1 // Январь = 0
      const day = today.getDate()

      if ((month === 12 && day >= 18) || (month === 1 && day <= 15)) {
        return '/logo-new-year.svg' // Новый год
      }

      return '/logo.svg'
    },
    getBackgroundImage () {
      const backgroundImages = {
        winter1: 'https://i.pinimg.com/736x/90/3b/75/903b7583d32bcee1dbd8bf21129411f6.jpg',
        winter2: 'https://i.pinimg.com/736x/ed/5e/29/ed5e295a3a5156f1f634bbc131ea0ab0.jpg',
        winter3: 'https://i.pinimg.com/736x/f2/f4/f7/f2f4f7588e9495580159ab41a2a1f3b7.jpg',
        spring1: 'https://i.pinimg.com/736x/8b/7b/a8/8b7ba8d1e248b033859be0694fdaab4a.jpg',
        spring2: 'https://i.pinimg.com/736x/3f/24/a9/3f24a9aa40a22fe42b293b5e1b42fea5.jpg',
        spring3: 'https://i.pinimg.com/736x/3c/fe/74/3cfe74beec1850d1c4454ac0a99b65b4.jpg',
        summer1: 'https://i.pinimg.com/736x/9f/77/9a/9f779a21a8bc9cfed92bbe8053b58a16.jpg',
        summer2: 'https://i.pinimg.com/736x/89/af/e3/89afe3dd7ceae4f316b29cb2e3fdd210.jpg',
        summer3: 'https://i.pinimg.com/736x/33/47/cc/3347cc018a9e20d08ce14e4757e60c68.jpg',
        fall1: 'https://i.pinimg.com/736x/a6/ee/ea/a6eeea1dda5de94bb38ba21747fc8f00.jpg',
        fall2: 'https://i.pinimg.com/736x/3b/6c/d0/3b6cd072eb2f87b72f2dc38f2bfad228.jpg',
        fall3: 'https://i.pinimg.com/736x/31/50/97/3150971de984711f1a9042f955b46a9f.jpg',
        new_year: 'https://i.pinimg.com/736x/06/6f/72/066f724d0b4ed6aa2c3dbdbe2dae8aa9.jpg',
        merry: 'https://i.pinimg.com/736x/dd/b7/a2/ddb7a241f93db269d98b4c3384dc5cff.jpg'
      }

      const today = new Date()
      const month = today.getMonth() + 1 // Январь = 0
      const day = today.getDate()

      // Проверка периодов
      // Рождество
      if ((month === 12 && day >= 25) && (month === 12 && day <= 26)) {
        return backgroundImages.merry // Рождество
      }

      if ((month === 12 && day >= 26) || (month === 1 && day <= 15)) {
        return backgroundImages.new_year // Новый год
      }

      // test
      // if (month === 11) {
      //   return backgroundImages.winter2
      // }

      switch (month) {
        case 1:
          return backgroundImages.winter2
        case 2:
          return backgroundImages.winter3
        case 3:
          return backgroundImages.spring1
        case 4:
          return backgroundImages.spring2
        case 5:
          return backgroundImages.spring3
        case 6:
          return backgroundImages.summer1
        case 7:
          return backgroundImages.summer2
        case 8:
          return backgroundImages.summer3
        case 9:
          return backgroundImages.fall1
        case 10:
          return backgroundImages.fall2
        case 11:
          return backgroundImages.fall3
        case 12:
          return backgroundImages.winter1
      }

      return backgroundImages.winter1
    }
  },
  created () {
    this.getAsideArticles()
  }
}
</script>

<style scoped>

</style>
