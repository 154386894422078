import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/filters'

export default {
  namespaced: true,
  state: {
    is_loading_filters: false,
    category_filters: [],
    selected_filters: [],
    show_panel: false,
    base_type: null
  },
  getters: {
    selectedFilterChips (state) {
      const ids = []
      state.selected_filters.forEach(item => {
        const typeData = item.split('_')
        state.category_filters.forEach(filter => {
          filter.properties.forEach(i => {
            // "property_type_id": 1, "property_value_id": 1, "value": "Intertool", "value_uk": "Intertool"
            if (i.property_type_id === parseInt(typeData[0]) && i.property_value_id === parseInt(typeData[1])) {
              ids.push(i)
            }
          })
        })
      })
      return ids
    }
  },
  mutations: {
    setCategoryFilters (state, data) {
      state.category_filters = data
    },
    setSelectedFilters (state, data) {
      state.selected_filters = data
    },
    setShowPanel (state, value) {
      state.show_panel = value
    },
    setBaseType (state, value) {
      state.base_type = value
    },
    deleteSelectedFilterItem (state, payload) {
      state.selected_filters = state.selected_filters.filter(function (item) {
        return item !== payload
      })
    }
  },
  actions: {
    getFilterList ({ state, commit }, data) {
      state.is_loading_filters = true
      axios.get(baseURL, { params: data }).then(response => {
        commit('setCategoryFilters', response.data.data)
        commit('setBaseType', response.data.base_type)
        state.is_loading_filters = false
      }).catch(e => {
        state.is_loading_filters = false
      })
    }
  }
}
