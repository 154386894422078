<template>
  <div class="etalon-home-page">
    <div class="etalon-bg" style="background: url('homePage/bgua.jpg')"></div>
    <header class="main-header" :class="{'etalon-dark-header': dark_header || show_menu}">
      <div class="etalon-home-container">
        <div class="box-image">
          <img src="/logo.png" alt="">
          <p>партнерство для успіху</p>
        </div>
        <div v-if="!$vuetify.breakpoint.mobile" class="menu">
          <div class="etalon-menu-item">
            <a class="etalon-home-link" href="#sec-description" v-smooth-scroll>Про нас</a>
          </div>
          <div class="etalon-menu-item">
            <a class="etalon-home-link" href="#sec-brends" v-smooth-scroll>Наш Портфель</a>
          </div>
          <div class="etalon-menu-item">
            <a class="etalon-home-link" href="#sec-contact" v-smooth-scroll>Контакти</a>
          </div>
          <div class="etalon-menu-item">
            <a class="etalon-home-link etalon-btn-info" href="/login">Вхід до кабінету</a>
          </div>
        </div>
        <div v-else class="menu">
          <v-btn large @click="show_menu = !show_menu" color="white" icon>
            <v-icon v-if="!show_menu" large>menu</v-icon>
            <v-icon v-if="show_menu" large>close</v-icon>
          </v-btn>
          <Transition name="fade">
            <div v-show="show_menu" class="etalon-mobile-menu">
              <div>
                <a @click="show_menu=false" class="etalon-item" href="#sec-description" v-smooth-scroll>Про нас</a>
              </div>
              <div>
                <a @click="show_menu=false" class="etalon-item" href="#sec-brends" v-smooth-scroll>Наш Портфель</a>
              </div>
              <div>
                <a @click="show_menu=false" class="etalon-item" href="#sec-contact" v-smooth-scroll>Контакти</a>
              </div>
              <div>
                <a class="etalon-item login" href="/login">Вхід до кабінету</a>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </header>
    <div class="hero-1">
      <div class="etalon-home-container">
        <div>
          <h1 class="main-promo">
            Лідер на ринку дистрибуції в Україні
          </h1>
          <a v-if="this.$vuetify.breakpoint.mobile" class="etalon-home-link etalon-btn-info mb-4" style="margin-top: 10px;display: inline-block" href="/login">
            Вхід до кабінету
          </a><br>
          <a class="etalon-home-link etalon-btn-info" style="margin-top: 10px;display: inline-block" href="/register">
            Стати партнером
          </a>
        </div>
      </div>
    </div>
    <div class="hero-1" style="margin-top: 100px">
      <div class="etalon-home-container" style="align-items: baseline">
        <v-row>
          <v-col cols="12" md="4">
            <div class="hero-text-section">
              <div class="title-num">26 000+</div>
              <div class="num-text">Товарних позицій на складі</div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="hero-text-section">
              <div class="title-num">603 700 &#13217;</div>
              <div class="num-text">Доставка по всій території України</div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="hero-text-section">
              <div class="title-num">14+</div>
              <div class="num-text">Років на ринку дистрибуції України</div>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="hero-text-section">
              <div class="title-num">7 400 &#13217;</div>
              <div class="num-text">Складських приміщень</div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="hero-text-section">
              <div class="title-num">8 700+</div>
              <div class="num-text">Клієнтів по всій Україні</div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="hero-text-section">
              <div class="title-num">2 500 000</div>
              <div class="num-text">Гривень на донати для ЗСУ</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div style="margin-bottom: 100px" id="sec-description"></div>
    <div class="hero-3">
      <div class="etalon-home-container">
        <div class="row mt-2 mb-4">
          <div class="col-12">
            <h1 class="mb-4" style="font-size: 3rem">Про нас</h1>
          </div>
          <div class="col-12">
            <div class="">
              TM Etalon - це компанія, що спеціалізується на дистрибуції більше ніж 26 000 товарних позицій. Наша мета -
              забезпечити вас якісними та надійними продуктами, що допоможуть вам розвивати ваш бізнес. Ми засновані в
              2009 році та маємо великий склад площа якого перевищує 7 400 квадратних метрів з реальним товаром та
              великими залишками. Наша компанія пропонує зручну логістику та доставку по всій території України.
            </div>
            <div class="mt-4">
              Ми спеціалізуємося на забезпеченні наших клієнтів засобами захисту, зварювальними, шліфувальними та іншими
              матеріалами для ремонту та будівництва, а також товарами для дому, кухні та здоров'я. Зараз у нас більше
              ніж
              8 700 активних клієнтів по всій Україні, і ми з гордістю можемо запропонувати їм найкращі ціни та
              обслуговування найвищої якості. Для зручності наших клієнтів ми надаємо можливість самостійного оформлення
              замовлень на нашому сайті.
            </div>
            <div class="mt-4">
              Ми завжди готові допомогти нашим партнерам у виборі необхідного товару та відповісти на їх запитання. Ми
              дбаємо про якість наших продуктів та сервісу, тому наші клієнти можуть бути впевнені в отриманні якісного
              товару та надійного сервісу. Звертайтеся до нас та переконайтеся самі!
            </div>
            <h3 class="mt-4">TM Etalon - партнерство для успіху!</h3>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 100px" id="sec-brends"></div>
    <div class="hero-2">
      <div class="etalon-home-container">
        <div class="row mt-2 mb-4">
          <div class="col-12">
            <h1 class="mb-4" style="color: white; font-size: 3rem">Наш Портфель</h1>
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Akfix.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Apro.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Biol.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Sila.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Elna.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Empire.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Fzb.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/intertool.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Imperial.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Kamille.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/LT.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Maestro.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Mastertool.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Miol.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/Noker.png">
          </div>
          <div class="col-12 col-md-3">
            <img style="max-width: 100%" src="/homePage/brands/PlasmaTec.png">
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 100px" id="sec-contact"></div>
    <div class="hero-2" style="background: none">
      <div class="etalon-home-container">
        <div class="row mt-8">
          <div class="col-12 col-lg-6">
            <h1 class="mb-4 contacts-phones">Наші контакти</h1>
            <h2 class="etalon-tel mt-4">Тех підтримка</h2>
            <h3 class="etalon-tel">
              <v-icon
                color="white"
                class="mr-2"
              >
                call
              </v-icon>
              <a class="" href="tel:380662517978">+38 (066) 251 79 78</a>
            </h3>
            <h3 class="etalon-tel">
              <v-icon
                color="white"
                class="mr-2"
              >
                mail
              </v-icon>
              <a style="font-size: 1rem" href="mailto:ceo.etalon.company@gmail.com">
                ceo.etalon.company@gmail.com
              </a>
            </h3>
            <h2 class="etalon-tel mt-3">Постачальникам та виробникам</h2>
            <p>Шановні партнери з пропозиціями співпраці звертайтесь будь ласка на електронну пошту:
              <a style="color:#ffc600;font-size: 1rem" href="mailto:etalon.ccg@gmail.com">
                <b>etalon.ccg@gmail.com</b>
              </a>
            </p>
          </div>
          <div class="col-12 col-lg-6"><img style="max-width: 100%" src="/homePage/ua.svg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// (function (d, w, s) {
//   const bch = d.createElement(s)
//   bch.type = 'text/javascript'
//   bch.async = true
//   bch.src = '//widgets.binotel.com/chat/widgets/9Hqxw8vK1JLEysWMxk7t.js'
//   const sn = d.getElementsByTagName(s)[0]
//   sn.parentNode.insertBefore(bch, sn)
// })(document, window, 'script')

export default {
  name: 'HomeView',
  data () {
    return {
      show_menu: false,
      dark_header: false
    }
  },
  methods: {
    handleScroll (e) {
      if (window.scrollY > 100) {
        this.dark_header = true
      } else {
        this.dark_header = false
      }
    },
    binotel () {
      (function (d, w, s) {
        const bch = d.createElement(s)
        bch.type = 'text/javascript'
        bch.async = true
        bch.src = '//widgets.binotel.com/chat/widgets/9Hqxw8vK1JLEysWMxk7t.js'
        const sn = d.getElementsByTagName(s)[0]
        sn.parentNode.insertBefore(bch, sn)
      }
      )(document, window, 'script')
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    this.binotel()
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
@import "../assets/scss/home";

.etalon-dark-header {
  //background: black;
  background: rgb(0 0 0 / 90%);
  //height: 80px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
