<template>
  <div class="page-404">
    <div class="head">
      <h1 class="title-404">404</h1>
      <div class="subtitle-404">Ошибка! Страница не найдена</div>
      <div class="link-404"><a href="/">Вернуться на главную</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  created () {

  }
}
</script>

<style lang="scss" scoped>
.page-404 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;

  .head {
    width: 360px;
    .title-404 {
      text-align: center;
      font-size: 200px;
    }
    .subtitle-404 {
      display: block;
      text-align: center;
    }
    .link-404 {
      display: block;
      text-align: center;
      a {
        color: #005eff;
      }
    }
  }
}
</style>
