import { render, staticRenderFns } from "./NotifyAvailableProduct.vue?vue&type=template&id=72284336&scoped=true"
import script from "./NotifyAvailableProduct.vue?vue&type=script&lang=js"
export * from "./NotifyAvailableProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72284336",
  null
  
)

export default component.exports