<script>
export default {
  name: 'NewYearComponent',
  data () {
    return {
      showMessage: false
    }
  },
  methods: {
    closeMessage () {
      this.showMessage = false
      // Устанавливаем флаг в LocalStorage
      localStorage.setItem('newYearMessageSeen', 'true')
    }
  },
  mounted () {
    // Проверяем текущую дату
    const now = new Date()
    const start = new Date('2024-12-25')
    const end = new Date('2025-01-14')

    if (now >= start && now <= end) {
      // Проверяем LocalStorage
      const hasSeenMessage = localStorage.getItem('newYearMessageSeen')
      if (!hasSeenMessage) {
        this.showMessage = true
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="showMessage" max-width="500">
    <v-card class="custom-card">
      <v-btn
        @click="closeMessage"
        style="right: 0"
        absolute
        icon
        color="white"
        large>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="new-y-title text-center text-white">
        <h1 class="text-white">Вітаємо!!!</h1>
        <h1 class="text-white">З Новим роком</h1>
        <h1 class="text-white">2025</h1>
      </div>
      <v-card-text class="new-y-text pb-2 pt-4">
        <b>Шановні клієнти!</b><br>
        <br>
        Щиро вітаємо вас із прийдешніми новорічними та різдвяними святами!<br>
        Нехай цей святковий час наповнить ваші серця радістю, а домівки — теплом і затишком.
        <br> Бажаємо вам міцного здоров’я, щастя, добробуту та, найголовніше, мирного неба над головою.
        <br><br>
        Звертаємо вашу увагу, що ми відновлюємо роботу 6 січня. Ми завжди раді вам і готові забезпечити найкращий сервіс та продукцію.
        <br>
        Дякуємо, що обираєте нас. Разом до кращого майбутнього!<br>
        <br>
        З найтеплішими побажаннями,<br>
        Команда компанії "Еталон"
        <div><v-btn class="mt-2" width="100%" color="primary" text @click="closeMessage">Закрити</v-btn></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.new-y-title {
  padding: 20px;
  background-image: url('/src/assets/scss/new-year-bg.jpg'); /* Ссылка на изображение */
  background-size: cover; /* Масштабирование изображения */
  background-position: center; /* Центрирование изображения */
}
.new-y-text {
  background: white;
  font-size: 14px;
}
.text-white {
  color: white;
}
</style>
