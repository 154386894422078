<template>
  <v-dialog
    v-model="dialog"
    width="720"
  >
    <v-card :loading="bot_link === null">
      <v-card-title class="text-h5">
        {{ $t('telegram.title') }}
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col sm="6">
          <v-card-text style="background: #80d8f0">
            <v-img src="/images/phone-telegram-bot.png"></v-img>
          </v-card-text>
        </v-col>
        <v-col sm="6">
          <v-card-text v-if="bot_link">
            <h2>{{ $t('telegram.head_title') }}</h2>
            <p class="mt-2">{{ $t('telegram.about') }}<br>
              {{ $t('telegram.available') }}<br>
              {{ $t('telegram.price') }}<br>
              - <b class="red--text">{{ $t('telegram.sales') }}</b>
            </p>
            <v-divider></v-divider>
            <p class="mt-2">
              <b>{{ $t('telegram.promo')}}</b>
              <qr-code
                class="mt-2"
                :size="150"
                :text="bot_link"
              >
              </qr-code>
            </p>
            <div class="mt-4">
              <v-btn
                color="blue"
                dark
                target="_blank"
                :href="bot_link"
              >{{ $t('telegram.join_btn')}}
              </v-btn>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          {{ $t('forms.close')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'JoinTelegramBotModal',
  props: ['value'],
  data () {
    return {
      bot_link: null
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    ...mapActions('users', ['getTelegramBotLink'])
  },
  watch: {
    dialog: function (v) {
      if (v) {
        const p = this.getTelegramBotLink()
        p.then(r => {
          this.bot_link = r.data.data
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
