<template>
  <div @click.stop style="width: 155px" class="d-flex align-center">
    <v-text-field
      v-model="qty"
      hide-details
      single-line
      dense
      type="number"
      class="qty-input"
      prepend-icon="remove"
      append-outer-icon="add"
      @click:append-outer="plus()"
      @click:prepend="minus()"
      @change="checkInput()"
    />
    <v-btn
      @click="addToCartRequest"
      color="ml-2"
      depressed
      fab
      small
    >
      <v-badge
        :content="inCart()"
        :value="inCart()"
        color="green"
        overlap
      >
        <v-icon class="mt-1" small>
          shopping_cart
        </v-icon>
      </v-badge>
    </v-btn>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  name: 'CartQtyInput',
  props: [
    'item'
  ],
  data () {
    return {
      timer: null,
      qty: 1
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('carts', ['cart'])
  },
  methods: {
    ...mapActions('carts', ['addToCart']),
    plus () {
      if (this.qty + this.item.qty_step <= this.item.qty) {
        this.qty = parseInt(this.qty) + parseInt(this.item.qty_step)
      }
    },
    minus () {
      if (this.qty - this.item.min_qty < 1) {
        return
      }

      if (this.qty - this.item.qty_step >= 1) {
        this.qty -= this.item.qty_step
      }
    },
    checkInput () {
      if (this.qty % this.item.qty_step) {
        this.qty = Math.round(this.qty / this.item.qty_step) * this.item.qty_step
      }

      if (this.qty - this.item.min_qty < 1) {
        this.qty = this.item.min_qty
      }

      if (this.qty < 1) {
        this.qty = this.item.qty_step
      }

      if (this.qty > this.item.qty) {
        this.qty = this.item.qty
      }
    },
    addToCartRequest () {
      const cartItem = {
        user_code_id: this.user.user_code_id,
        product_id: this.item.id,
        qty: this.qty
      }

      this.addToCart(cartItem)
    },
    inCart () {
      const index = this.cart.findIndex(item => item.product_id === this.item.id)
      if (index !== -1) {
        return this.cart[index] ? this.cart[index].qty : 99
      }

      return 0
    }
  },
  created () {
    this.qty = this.item.min_qty
  }
}
</script>

<style scoped>
.qty-input >>> input {
  text-align: center;
}
</style>
