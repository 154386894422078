import { render, staticRenderFns } from "./JoinTelegramBotModal.vue?vue&type=template&id=3a159bbc&scoped=true"
import script from "./JoinTelegramBotModal.vue?vue&type=script&lang=js"
export * from "./JoinTelegramBotModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a159bbc",
  null
  
)

export default component.exports