import axios from 'axios'

const baseURL = process.env.VUE_APP_AUTH_SERVER + '/api/users'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async updateUser ({ dispatch, commit }, data) {
      const promise = axios.put(baseURL, data)

      promise.then(response => {
        dispatch('auth/getUser', null, { root: true })
      })

      return promise
    },
    async getTelegramBotLink ({ dispatch, commit }) {
      return axios.get(baseURL + '/get-telegram-bot-link')
    }
  }
}
