<template>
  <div class="d-flex align-center">
    <span class="pe-2 grey--text">{{ $t('products.not_available') }}</span>
    <v-tooltip bottom v-if="!product.product_notifications">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click.stop="notifyWhenAvailable(product.id)"
          color="orange"
          icon
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>notifications</v-icon>
        </v-btn>
      </template>
      {{ $t('notifications.notify_available') }}
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click.stop="deleteNotifyWhenAvailable(product.id)"
          color="green"
          icon
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>notifications_off</v-icon>
        </v-btn>
      </template>
      {{ $t('notifications.cancel_notify_available') }}
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NotifyAvailableProduct',
  props: ['product'],
  methods: {
    ...mapActions('products', [
      'notifyWhenAvailable',
      'deleteNotifyWhenAvailable'
    ])
  }
}
</script>

<style scoped>

</style>
