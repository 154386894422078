import axios from 'axios'

const baseURL = process.env.VUE_APP_AUTH_SERVER + '/api/portal-clients'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async searchPortalClients ({ commit }, filters) {
      return axios.get(baseURL, {
        params: filters
      })
    }
  }
}
