import axios from 'axios'
import store from '../store'

const RequestErrors = {
  connect () {
    axios.interceptors.response.use((response) => {
      store.commit('errors/clearErrors')
      return response
    }, function (error) {
      if (error.response.status === 422) {
        store.commit('errors/setErrors', error.response.data)
      }
      return Promise.reject(error)
    })
  }
}

export default RequestErrors
