import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/categories'

export default {
  namespaced: true,
  state: {
    breadcrumbs: [],
    categories: [],
    list: [],
    root_categories: [],
    parents: [],
    parent: null,
    filters: {
      parent_id: null
    }
  },
  mutations: {
    setCategories (state, payload) {
      state.categories = payload
    },
    setRootCategories (state, payload) {
      state.root_categories = payload
    },
    setParent (state, category) {
      state.parent = category
      state.filters.parent_id = category ? category.id : null
    },
    setParents (state, payload) {
      state.parents = payload
    },
    setList (state, payload) {
      state.list = payload
    },
    setParentId (state, id) {
      state.filters.parent_id = id
    },
    setBreadcrumbs (state, payload) {
      state.breadcrumbs = payload
    }
  },
  actions: {
    async getCatalog ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL, {
        params: {
          parent_id: state.filters.parent_id,
          per_page: 100
        }
      })
      promise.then(response => {
        commit('setCategories', response.data.data)
        commit('setParent', response.data.parent)
        commit('setBreadcrumbs', response.data.breadcrumbs)
      })

      return promise
    },
    async getRootCatalog ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL, {
        params: {
          per_page: 200
        }
      })
      promise.then(response => {
        commit('setRootCategories', response.data.data)
      })

      return promise
    },
    async getParents ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL + '/parents', {
        params: {
          per_page: 200
        }
      })
      promise.then(response => {
        commit('setParents', response.data.data)
      })

      return promise
    },
    async getList ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL + '/list')
      promise.then(response => {
        commit('setList', response.data.data)
      })

      return promise
    },
    async createCategory ({
      commit,
      dispatch
    }, data) {
      data.parent_id = data.parent ? data.parent.id : null
      const promise = axios.post(baseURL, data).then(r => {
        dispatch('getCatalog')
      })

      return promise
    },
    async updateCategory ({
      commit,
      dispatch
    }, data) {
      data.parent_id = data.parent ? data.parent.id : null
      const promise = axios.put(baseURL + '/' + data.id, data).then(r => {
        dispatch('getCatalog')
      })

      return promise
    },
    async deleteCategory ({
      commit,
      dispatch
    }, id) {
      const promise = axios.delete(baseURL + '/' + id).then(r => {
        dispatch('getCatalog')
      })

      return promise
    }
  }
}
