import axios from 'axios'

const baseURL = process.env.VUE_APP_AUTH_SERVER

export default {
  namespaced: true,
  state: {
    user: null,
    access_token: `Bearer ${localStorage.getItem('access_token') || ''}`
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setAccessToken (state, token) {
      state.access_token = token
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
      localStorage.setItem('access_token', token)
    }
  },
  actions: {
    async registerRequest ({ commit }, data) {
      return axios.post(baseURL + '/api/register', data)
    },
    async loginRequest ({ commit }, data) {
      const promise = axios.post(baseURL + '/api/login', data)

      promise.then(response => {
        commit('setAccessToken', response.data.access_token)
      })

      return promise
    },
    async getUser ({ commit }) {
      const promise = axios.get(baseURL + '/api/auth')

      promise.then(response => {
        commit('setUser', response.data.data)
        commit('lang/setLang', response.data.data.lang, { root: true })
      }).catch(error => {
        if (error.response.status === 401 && window.location.pathname !== '/login') {
          localStorage.setItem('access_token', null)
          window.location = '/login'
        }
      })

      return promise
    },
    logout ({ commit }) {
      commit('setUser', null)
      localStorage.setItem('access_token', null)
      window.location = '/'
    }
  }
}
