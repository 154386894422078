import axios from 'axios'

const baseURL = 'https://api.novaposhta.ua/v2.0/json/'

export default {
  namespaced: true,
  state: {
    searchCity: null,
    searchBranch: null,
    cities: [],
    branches: []
  },
  mutations: {
    setSearchCity (state, v) {
      state.searchCity = v
      console.log('changed', v)
    },
    setSearchBranch (state, v) {
      state.searchBranch = v
    },
    setCities (state, payload) {
      state.cities = payload
    },
    setBranches (state, payload) {
      state.branches = payload
    }
  },
  actions: {
    getCities ({ state, commit }) {
      const instance = axios.create()
      delete instance.defaults.headers

      const data = {
        modelName: 'Address',
        calledMethod: 'getCities',
        methodProperties: {
          FindByString: state.searchCity,
          Limit: 10,
          Page: 1
        }
      }

      instance.post(baseURL, data).then(response => {
        commit('setCities', response.data.data)
      })
    },
    getBranches ({ state, commit }, cityRef) {
      const instance = axios.create()
      delete instance.defaults.headers

      const data = {
        modelName: 'AddressGeneral',
        calledMethod: 'getWarehouses',
        methodProperties: {
          CityRef: cityRef,
          FindByString: state.searchBranch,
          Page: '1',
          Limit: '30'
        }
      }

      instance.post(baseURL, data).then(response => {
        console.log(response)
        commit('setBranches', response.data.data)
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
