<template>
  <v-container fluid>
    <v-layout v-if="!completed" class="mt-4" align-center justify-center>
      <v-card flat width="360">
        <a class="text-center set-font" style="color: black; text-decoration: none" href="/">
          <v-img class="mb-0" height="50px" style="margin: auto" max-width="300px" src="logo-dark.png"/>
          <p style="font-size: 20px">партнерство для успіху</p>
        </a>
        <v-divider></v-divider>
        <v-toolbar
          flat
        >
          <v-toolbar-title style="margin: auto">
            <b>Заповніть реєстраційну форму</b>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          Ми зв'яжемося з Вами протягом одного робочого дня.
        </v-card-text>

        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              dense
              autofocus
              outlined
              v-model="name"
              label="Ім'я"
              type="text"
              name="name"
              :error="hasError('name')"
              :error-messages="getError('name')"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              v-model="phone"
              label="Номер телефону*"
              placeholder="+38 (097) 000 00 00"
              type="text"
              name="phone"
              :error="hasError('phone')"
              :error-messages="getError('phone')"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              v-model="city"
              label="Населений пункт"
              placeholder="м. Київ"
              type="text"
              name="city"
              :error="hasError('city')"
              :error-messages="getError('city')"
            ></v-text-field>
            <p>Вид діяльності</p>
            <v-checkbox
              v-for="(type, index) in client_type"
              v-model="selectedType"
              :label="type"
              color="green"
              :value="type"
              hide-details
              :key="index"
            ></v-checkbox>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mt-4"
              width="100%"
              color="primary"
              @click="sendRegister()"
              large
              :disabled="sending"
              :loading="sending"
            >
              Відправити
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-layout>
    <v-layout v-else class="mt-4" align-center justify-center>
      <v-alert
        max-width="360px"
        outlined
        type="success"
        text
      >
        <h2>Дякуємо</h2>
        <div>
          Ми отримали вашу заявку на реєстрацію та найближчим часом зв'яжемося з вами. З повагою команда TM ETALON
        </div>
        <v-btn color="green" class="mt-4" dark @click="$router.push('/')">На головну</v-btn>
      </v-alert>
    </v-layout>
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PartnerView',
  data: () => ({
    name: null,
    phone: '',
    city: '',
    selectedType: null,
    client_type: [
      'Інтернет-магазин',
      'Роздрібна торгівля'
    ],
    completed: false,
    sending: false
  }),
  watch: {
    user: {
      handler (v) {
        if (v.id) {
          this.$router.push('/dashboard/catalog')
        }
      }
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError', 'getError'])
  },
  methods: {
    ...mapActions('auth', ['registerRequest']),
    sendRegister () {
      this.sending = true
      const promise = this.registerRequest({
        name: this.name,
        phone: this.phone,
        city: this.city,
        type: this.selectedType
      })

      promise.then(r => {
        this.completed = true
      }).catch(e => {
        this.sending = false
      })
    }
  },
  created () {
  }
}
</script>
