import axios from 'axios'

const baseURL = process.env.VUE_APP_CONTENT_SERVICE + '/api/articles'

export default {
  namespaced: true,
  state: {
    aside_articles: [],
    article: null
  },
  mutations: {
    setAsideArticles (state, v) {
      state.aside_articles = v
    },
    setArticle (state, v) {
      state.article = v
    }
  },
  actions: {
    async getAsideArticles ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL, {
        params: {
          position: 1
        }
      })

      promise.then(response => {
        commit('setAsideArticles', response.data.data)
      }).catch(e => {
      })
      return promise
    },
    async getArticle ({
      state,
      commit
    }, id) {
      const promise = axios.get(baseURL + '/' + id, {})

      promise.then(response => {
        commit('setArticle', response.data.data)
      }).catch(e => {
      })
      return promise
    }
  }
}
