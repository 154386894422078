export default {
  namespaced: true,
  state: {
    is_show_alert: false,
    type: 'error',
    message: null
  },
  mutations: {
    setIsShowAlert (state, v) {
      state.is_show_alert = v
    },
    setShowAlert (state, notify) {
      state.is_show_alert = true
      state.type = notify.type
      state.message = notify.text
    },
    clearAlert (state) {
      state.is_show_alert = false
      state.type = ''
      state.message = null
    }
  },
  actions: {

  }
}
